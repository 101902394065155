<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("webhooks.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[{text: this.$t('webhooks.delete'), callback: deleteElement, class: 'red--text'}]"
        >{{ $t("webhooks.edit") }}
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">

            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model="link"
                        :disabled="loading"
                        :error-messages="linkErrors"
                        :label="$t('webhooks.link')"
                        outlined
                        @blur="$v.link.$touch()"
                        @input="$v.link.$touch()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                        v-model="events"
                        :disabled="loading"
                        :error-messages="eventsErrors"
                        :items="available_events"
                        :label="$t('webhooks.events')"
                        :no-data-text="$t('webhooks.no_events')"
                        chips
                        multiple
                        outlined
                        persistent-hint
                        @blur="$v.events.$touch()"
                        @input="$v.events.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="signature"
                        :disabled="loading"
                        :label="$t('webhooks.signature')"
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("webhooks.add") : $t("webhooks.update") }}
            </v-btn>
        </v-form>

        <v-timeline
            class="mt-10"
            clipped
            dense
        >

            <v-timeline-item
                v-for="item in history"
                :key="item"
                class="mb-4"
                small
            >
                <v-row justify="space-between">
                    <v-col cols="7">
                        <v-chip
                            class="white--text ml-0"
                            :color="item.status >= 200 && item.status <= 299 ? 'green' : 'red'"
                            label
                            small
                        >
                            {{ item.status }}
                        </v-chip>
                        <br/>
                        <code>{{ item.body }}</code>
                    </v-col>
                    <v-col
                        class="text-right"
                        cols="5"
                    >
                        {{ item.created_at }}
                    </v-col>
                </v-row>
            </v-timeline-item>

        </v-timeline>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Details",
    components: {TitleBar, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.webhooks"),
                to: "/webhooks",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("webhooks.new") : this.$t("webhooks.edit"),
                to: "/webhooks/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.loading = true;
        let customers = await Accounting.get("/webhooks/events").catch(() => {
        });
        this.available_events = customers.data.events;
        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;

            Accounting.get("/webhooks/" + this.$route.params.uuid + "/history")
                .then(resp => {
                    this.history = resp.data.history;
                })
                .catch(() => {
                });

            Accounting.get("/webhooks/" + this.$route.params.uuid)
                .then(response => {
                    const data = response.data;
                    this.events = data.events;
                    this.link = data.link;
                    this.signature = data.signature;
                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }


    },
    data: () => {
        return {
            loading: false,
            signature: null,
            events: null,
            available_events: [],
            link: null,
            history: []
        };
    },
    methods: {
        deleteElement() {
            this.$swal({
                title: this.$t("webhooks.delete_title"),
                text: this.$t("webhooks.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("webhooks.delete_yes"),
                cancelButtonText: this.$t("webhooks.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/webhooks/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("webhooks.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/webhooks");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/webhooks";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    events: this.events,
                    link: this.link,
                    signature: this.signature
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("webhooks.added")
                        }]);
                        this.$router.push("/webhooks/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("webhooks.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        events: {required},
        link: {required, url}
    },
    computed: {
        eventsErrors() {
            const errors = [];
            if (!this.$v.events.$dirty) return errors;
            !this.$v.events.required && errors.push(this.$t("webhooks.events_required"));
            return errors;
        },
        linkErrors() {
            const errors = [];
            if (!this.$v.link.$dirty) return errors;
            !this.$v.link.required && errors.push(this.$t("webhooks.link_required"));
            !this.$v.link.url && errors.push(this.$t("webhooks.link_url_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>